const _Alert = {
    /**
     * @callback actionCallback
     * @param {MouseEvent} e - click event
     */

    /**
     * Display alert
     * @param {string} titleText 
     * @param {string[]} bodyText 
     * @param {bool} isSuccess 
     * @param {string} primaryText 
     * @param {actionCallback} buttonCallback 
     * @param {string} secondaryText 
     * @param {string} secondaryUrl 
     */
    open: function(titleText, bodyText, isSuccess, primaryText, primaryCallback, secondaryText, secondaryUrl) {
        // Init values
        _init();
        if(_scrim.parentNode) _Alert.close(true);
        // Add scrim
        document.body.append(_scrim);
        // Add alert
        isSuccess = (isSuccess === false) ? false : true;
        const headerClass = (isSuccess) ? 'alert-success' : 'alert-error';
        const paths = ["M300,461.4c-15.9,0-28.8-12.9-28.8-28.8s12.9-28.8,28.8-28.8c15.9,0,28.8,12.9,28.8,28.8S315.9,461.4,300,461.4zM331.3,171.3l-8.3,176c0,12.7-10.3,23.1-23.1,23.1s-23.1-10.3-23.1-23.1l-8.3-175.9v-0.1c-0.7-17.3,12.7-31.9,30-32.7c17.3-0.7,31.9,12.7,32.7,30C331.3,169.5,331.3,170.4,331.3,171.3L331.3,171.3z",
        "M456.1,199.5L262.3,430.2c-4.3,5.1-10.6,8.1-17.3,8.2h-0.4c-6.5,0-12.8-2.8-17.1-7.6l-83.1-92.3c-8.7-9.3-8.3-23.9,1-32.6c9.3-8.7,23.9-8.3,32.6,1c0.2,0.3,0.5,0.5,0.7,0.8l65.3,72.6l176.8-210.4c8.3-9.6,22.9-10.7,32.6-2.4C462.8,175.6,464,189.8,456.1,199.5L456.1,199.5z"];
        _alertHead.className = `alert-head ${headerClass}`;
        _alertHeadIconPath.setAttribute('d', (isSuccess) ? paths[1] : paths[0]);
        _alertHeadText.innerText = titleText;
        _alertBody.innerHTML = bodyText.map((str) => `<p>${str}</p>`).join('');
        _alertActionPrimary.style.display = (primaryText) ? null : 'none';
        _alertActionSecondary.style.display = (secondaryText) ? null : 'none';
        _alertActionPrimary.textContent = primaryText;
        _alertActionSecondary.textContent = secondaryText;
        _alertActionSecondary.setAttribute('href', secondaryUrl);
        _currentPrimaryCallback = primaryCallback;
        if(_currentPrimaryCallback) _alertActionPrimary.addEventListener('click', _currentPrimaryCallback);
        document.body.append(_alert);
        // Animate In
        setTimeout(() => { 
            _scrim.classList.add('in'); 
            _alert.classList.add('in');
        }, 100);
    },
    close: function(immediate = false) {
        // Init values
        _init();
        // Remove listeners
        if(_currentPrimaryCallback) _alertActionPrimary.removeEventListener('click', _currentPrimaryCallback);
        // Fade scrim and alert
        _scrim.classList.remove('in');
        _alert.classList.remove('in');
        // Remove elements
        if(immediate) {
            _removeElements();
        } else {
            setTimeout(() => {
                _removeElements();
            }, 750);
        }
    },
};

/**
 * Helper to create Elements
 * @param {string} tag 
 * @returns {HTMLElement}
 */
function _createElement(tag, ns) { return (ns) ? document.createElementNS(ns, tag) : document.createElement(tag); }
const _scrim = _createElement('div');
const _alert = _createElement('div');
const _alertHead = _createElement('div');
const _svgNs = 'http://www.w3.org/2000/svg'
const _alertHeadIcon = _createElement('svg', _svgNs);
const _alertHeadIconPath = _createElement('path', _svgNs);
const _alertHeadText = _createElement('h1');
const _alertBody = _createElement('div');
const _alertAction = _createElement('div');
const _alertActionPrimary = _createElement('button');
const _alertActionSecondary = _createElement('a');

function _init() {
    if(_isInit) return;
    _isInit = true;
    _scrim.className = 'alert-scrim';
    _alert.className = 'alert';
    _alertHead.className = 'alert-head';
    _alertHeadIcon.setAttribute('class', 'alert-icon');
    _alertHeadIcon.setAttribute('viewBox', '0 0 600 600');
    _alertHeadIconPath.setAttribute('class', 'alert-icon-path');
    _alertHeadText.className = 'alert-title';
    _alertBody.className = 'alert-body';
    _alertAction.className = 'alert-action';
    _alertActionPrimary.className = 'alert-btn alert-btn-primary';
    _alertActionSecondary.className = 'alert-btn alert-btn-secondary';
    _alertHeadIcon.append(_alertHeadIconPath);
    _alertHead.append(_alertHeadIcon);
    _alertHead.append(_alertHeadText);
    _alert.append(_alertHead);
    _alert.append(_alertBody);
    _alertAction.append(_alertActionPrimary);
    _alertAction.append(_alertActionSecondary);
    _alert.append(_alertAction);
    // Add listeners
    _scrim.addEventListener('click', () => {
        _Alert.close();
    });
}
function _removeElements() {
    document.body.removeChild(_scrim);
    document.body.removeChild(_alert);
}

let _isInit = false;
let _currentPrimaryCallback;

export const Alert = _Alert;