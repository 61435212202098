import '../css/b.css';
import logoImage from '../img/logo.svg';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import 'intersection-observer';
import { Alert } from  './alert.js';

console.log("Welcome to the alternate landing page.", Alert);
/**
 * Helper for document.getElementById
 * @param {string} id 
 * @returns {HTMLElement}
 */
const getElementById = function (id) { return document.getElementById(id); };
const endpoint = API_URL;
const events = [];
const logo = getElementById('logo');
const hero = getElementById('hero-img');
const introHead = getElementById('intro-head'); 
const introPara = getElementById('intro-body'); 
const buildBtn = getElementById('intro-btn');
const heroImage = {
    'jpg': require('../img/cover.jpg'),
    'webp': require('../webp/cover.jpg.webp'),
};
const sectionImages = [
    [require('../img/sec-1-fg.png'), require('../img/sec-1-bg.png')],
    [require('../img/sec-2-fg.png'), require('../img/sec-2-bg.png')],
    [require('../img/sec-3-fg.png'), require('../img/sec-3-bg.png')],
    [require('../img/sec-4-fg.png'), require('../img/sec-4-bg.png')],
    [require('../img/sec-5-fg.png'), require('../img/sec-5-bg.png')],
    [require('../img/sec-6-fg.png'), require('../img/sec-6-bg.png')],
];
const introCopy = [
    ["Get a free review of your app, marketing & more.", "Get a free review of your brand, marketing & more.", "Get a free review of your marketing and more.", "Get a free review of your website and marketing."],
    ["We’re offering a complete review of your marketing mix, so you can maximise sales, get more installs, and build engagement. It’s totally free and customised to your specific needs.", "We’re offering a complete review of your marketing mix, so you can maximise sales, win more clients, and build a following. It’s totally free and customised to your specific needs."],
    ["BUILD MY HEALTH CHECK","BUILD MY REPORT","GET STARTED"],
];
const introVersions = {
    "a": [2,1,1],
    "b": [2,1,2],
    "c": [2,1,0],
    "d": [3,1,2],
    "e": [1,1,1],
    "f": [0,0,1],
    "g": [3,1,1],
    "h": [3,1,0],
    "z": [2,1,0],
};
const marketingSequence = [0, 4, 6, 1, 2, 3, 5, 7, 8];
const webSequence       = [0, 2, 3, 6, 4, 1, 5, 7, 8];
const brandSequence     = [0, 1, 6, 4, 2, 3, 5, 7, 8];
const appSequence       = [0, 3, 4, 6, 2, 1, 5, 7, 8];
const stageSequences = {
    "a": marketingSequence,
    "b": marketingSequence,
    "c": marketingSequence,
    "d": webSequence,
    "e": brandSequence,
    "f": appSequence,
    "g": webSequence,
    "h": webSequence,
    "z": [0, 1, 2, 3, 4, 5, 6, 7, 8],
};
const requestData = {};
const formElements = {};
IntersectionObserver.prototype.USE_MUTATION_OBSERVER = false;
const observer = new IntersectionObserver(eventVisible, {
    threshold: [0, 0.5],
});

let apiToken;
let isWebPSupported = false;
let introVersion = "z";
let stageSequence = [];

/**
 * Main execution function
 */
function main() {
    // TODO: Display/Hide load spinner
    // Reorder sections
    initSections();
    // Setup listeners
    setListeners();
}

/**
 * Process page sections and set order
 */
function initSections() {
    addEvent('op');
    getUrlParameters();
    // Set intro text
    const headline = introVersions[introVersion][0];
    const bodyText = introVersions[introVersion][1];
    const btnLabel = introVersions[introVersion][2];
    stageSequence = (stageSequences[introVersion]) ? stageSequences[introVersion] : stageSequences.z;
    introHead.innerText = introCopy[0][headline];
    introPara.innerText = introCopy[1][bodyText];
    buildBtn.innerText = introCopy[2][btnLabel];
    
    // Set section sequence
    stageSequence = (stageSequences[introVersion]) ? stageSequences[introVersion] : stageSequences.z;
    const container = document.body;
    stageSequence.forEach((index) => {
        const section = getElementById(`sec-${index}`);
        if(section) container.append(section);
    });

    // Store references to form sections
    document.querySelectorAll('.fg').forEach((el) => {
        if(!el.dataset.section) return;
        formElements[el.dataset.section] = el;
    });
}

/**
 * Add listeners to elements
 */
function setListeners() {
    // Load listeners
    window.addEventListener('load', eventInit);
    document.addEventListener('visibilitychange', () => {
        addEvent('vc');
        sendEvents();
    });
    setTimeout(() => {
        setInterval(sendEvents, 5000);
    }, 1000);

    // Scroll listeners
    document.querySelectorAll('.sec-img-bg,.sec-img-fg').forEach((el) => el.classList.add('out'));
    for(let i = 1; i < 7; i++) {
        observer.observe(getElementById(`sec-${i}`));
    }

    // Button listeners
    const buttons = document.querySelectorAll('button');
    buttons.forEach((button) => button.addEventListener('click', eventButtonClick));
    // Change listeners
    const form = document.forms[0];
    form.addEventListener('change', eventChange);
    // Submit listeners
    form.addEventListener('submit', eventSubmit);
}

/**
 * Page loaded event listener
 */
 function eventInit() {
    // Test Webp Support
    testWebp().then((supported) => {
        isWebPSupported = supported;
        // Load logo image
        return loadImage(logoImage);
    }).then((el) => {
        addEvent('ld');
        logo.style.backgroundImage = `url(${el.src})`;
        logo.children[0].style.visibility = 'hidden';
        // Reveal text
        document.querySelectorAll('.card').forEach((el) => el.classList.remove('hide'));
        // Get Api Token
        fetchToken().then((token) => apiToken = token).catch((e) => console.error(e));
        // Load Hero image
        return loadImage(isWebPSupported ? heroImage.webp.default : heroImage.jpg.default);
    }).then((el) => {
        addEvent('in');
        hero.style.backgroundImage = `url(${el.src})`;
        hero.classList.add('on');
    }).catch(e => console.error(e));
}

/**
 * Handler for all button clicks
 * @param {MouseEvent} e 
 */
function eventButtonClick(e) {
    /** @type {HTMLButtonElement} */
    const button = this;
    if(button.name.startsWith('sec')) {
        // Store section selection
        const sectionName = button.name.substring(4);
        requestData[sectionName] = (button.value === "1") ? 'Yes' : 'No';
        formElements[sectionName].style.display = (button.value === "1") ? 'block' : 'none';
    }
    if(button.dataset.target) {
        // Scroll to target
        const node = getElementById(button.dataset.target);
        if(node) smoothScrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed', block: 'start', duration: 750 });
    }
    if(button.name === 'submit') {
        // Handled on form submit
    }
}

/**
 * Handler for observer event
 * @param {IntersectionObserverEntry[]} entries 
 */
function eventVisible(entries) {
    entries.forEach((entry) => {
        if(!entry.isIntersecting) return;

        const index = parseInt(entry.target.id.substring(4)) - 1;
        const bgContainer = entry.target.querySelector('.sec-img-bg');
        const fgContainer = entry.target.querySelector('.sec-img-fg');
        // loadState: 0 - init, 1 - loading, 2 - loaded
        // showState: 0 - init, 1 - canShow, 2 - showing 

        // Start loading images
        if(!entry.target.dataset.loadState) {
            entry.target.dataset.loadState = 1;
            entry.target.dataset.showState = 0;
            
            Promise.all(sectionImages[index].map((req) => loadImage(req.default)))
            .then((images) => {
                entry.target.dataset.loadState = 2;
                fgContainer.style.backgroundImage = `url(${images[0].src})`;
                bgContainer.style.backgroundImage = `url(${images[1].src})`;

                // Reveal images if page has already scrolled into view
                if(entry.target.dataset.showState == "1") {
                    bgContainer.classList.remove('out');
                    fgContainer.classList.remove('out');
                    entry.target.dataset.showState = 2;
                    console.log(entry.target.dataset.showState);
                }
            });
        }

        // Stop observing once well within viewport
        if(entry.intersectionRatio >= 0.5) {
            addEvent('nx');
            entry.target.dataset.showState = 1;
            observer.unobserve(entry.target);
        }
        
        // Reveal images if loaded & well within viewport
        if(entry.target.dataset.loadState == "2" && entry.target.dataset.showState == "1") {
            bgContainer.classList.remove('out');
            fgContainer.classList.remove('out');
            entry.target.dataset.showState = 2;
        }
    });
}

/**
 * Handle input change event
 * @param {Event} e 
 */
function eventChange(e) {
    const input = e.target;
    addEvent('ch');
    if(input.type == "radio") {
        /** @type {HTMLInputElement} */
        const phoneInput = getElementById('I4');
        phoneInput.required = input.value != "A";
        phoneInput.labels[0].textContent = "Phone " + ((input.value == "A") ? "(Optional)" : "(Required)");
    }
}

/**
 * Handle form submit event
 * @param {Event} e 
 */
function eventSubmit(e) {
    e.preventDefault();
    // TODO: Submit form

    // Disable button
    /** @type {HTMLFormElement} */
    const form = this;
    /** @type {HTMLButtonElement} */
    const button = getElementById('I6');
    button.disabled = true;
    button.classList.add('btn-busy');

    const data = {};
    const valid = form.checkValidity();

    if(!valid) {
        addEvent('sbi');
        // Scroll to first element that is invalid
        const node = (function() {for (let i = 0; i < form.elements.length; i++) {
            if (form.elements[i].checkValidity && form.elements[i].checkValidity() === false) return form.elements[i];
        }})();
        if(node) {
            smoothScrollIntoView(node, { behavior: 'smooth', scrollMode: 'always', block: 'center', duration: 750 });
            node.focus();
        }
        button.disabled = false;
        button.classList.remove('btn-busy');
        return false;
    }
    
    // Get token
    addEvent('sbv');
    const tokenPromise = (apiToken) ? Promise.resolve(apiToken) : fetchToken();
    tokenPromise.then((token) => {
        if(!token) throw new Error("Failed loading token");

        // Prepare payload
        data.s = { r: requestData };
        data.e = [...events];
        data.id = apiToken;
        events.length = 0;
        
        // Iterate over the form controls
        for (let i = 0; i < form.elements.length; i++) {
            if (form.elements[i].nodeName === "BUTTON" || (form.elements[i].type === "radio" && !form.elements[i].checked)) continue;
            data.s[form.elements[i].id] = form.elements[i].value;
        }

        // Submit items
        return getHttpRequest(data);
    }).then((res) => {
        // Display success
        if(res !== "OK") throw new Error("Failed submitting data");
        button.disabled = false;
        button.classList.remove('btn-busy');
        Alert.open('All done!', ['We’re working hard to compile your report now. You should expect to hear from us within 5 working days.', 'In the meantime, tap below to discover what Animate Designs can do for you.'], true, null, null, 'Find out more', 'https://www.animatedesigns.com/');
    }).catch((e) => {
        // Display failure
        button.disabled = false;
        button.classList.remove('btn-busy');
        console.error(e);
        Alert.open('Oops!', ['There was a problem sending your request.'], false, 'RETRY', () => {
            Alert.close();
            setTimeout(() => {
                form.submit.click();
            }, 750);
        }, 'CONTACT', 'https://www.animatedesigns.com/#Contact');
    });
    return true;
}

/**
 * Load options from the url query string
 */
function getUrlParameters() {
    const params = new URLSearchParams(window.location.search);
    if(params.has('k')) {
        // Add Keyword to event beacon
        addEvent('ky', params.get('k'));
    }
    if(params.has('v')) {
        const paramVersion = params.get('v').toLowerCase();
        if(introVersions[paramVersion]) introVersion = paramVersion;
        addEvent('vr', introVersion);
    }
}

/**
 * Get the ID Token from server
 * @returns {Promise<string>}
 */
function fetchToken() {
    return getHttpRequest({id:0});
}

/**
 * Issue an XMLHttpRequest sending a JSON object
 * @param {object} data 
 * @returns {Promise<string>}
 */
function getHttpRequest(data) {
    if(!data) data = {};
    return new Promise(function (resolve, reject) {
        const req = new XMLHttpRequest();
        req.open('POST', endpoint, true);
        req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        req.setRequestHeader('Accept', 'application/json');
        req.onload = function (e) {
            if (req.readyState === 4) {
                if (req.status === 200) {
                    resolve(req.responseText);
                } else {
                    reject(req.statusText);
                }
            }
        };
        req.onabort = req.onerror = req.ontimeout = (e) => reject(e);
        req.send('q=' + JSON.stringify(data));
    });
}

/**
 * Append an event with ID and timestamp to event list
 * @param {string} type Event type ID
 * @param {string} data Optional data
 */
function addEvent(type, data) {
    const ev = { t: type, s: Math.floor(performance.now()) };
    if(data) ev.d = data;
    // Append event data with timestamp
    events.push(ev);
}

/**
 * Submit events to API
 */
function sendEvents() {
    if(!apiToken || events.length < 1) return;
    
    const data = new FormData();
    data.append('q', JSON.stringify({ id: apiToken, e: events }));
    getHttpRequest({id: apiToken, e: events}).then((result) => {
        if(result !== 'OK') console.log(result);
    }).catch(e => console.error(e));
    events.length = 0;
}

/**
 * Async load an image
 * @param {string} url 
 * @returns {Promise<HTMLImageElement>}
 */
function loadImage(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onabort = img.onerror = (e) => { reject(e); }
        img.onload = () => resolve(img);
        img.src = url;
    });
}

/**
 * Test if webP images are supported
 * @returns {Promise<boolean>}
 */
function testWebp() {
    return loadImage('data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA').then((image) => image.width === 1).catch((e) => {console.error(e); return false});
}

main();